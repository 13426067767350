<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="transferUsers" />
        <ASuccessWithoutReload :api="this.api" @close="() => {
            this.api.isSuccesful = false;
        }" />
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <v-data-table v-if="!api.isLoading && data != null" :items="data" :headers="dataHeader" :search="search"
            class="elevation-1 mt-4">
            <template v-slot:item.Index="{ item }">
                {{ data.indexOf(item) + 1 }}
            </template>
            <template v-slot:top>
                <v-toolbar width="auto" height="auto" class="py-3" flat>
                    <v-row no-gutters>
                        <v-col class="mt-3 mx-3">
                            <v-row class="mb-3">
                                <v-toolbar-title class="font-weight-bold text-h4 my-3">
                                    Onboarding
                                </v-toolbar-title>
                                <v-spacer>
                                </v-spacer>
                        
                                <v-btn color="primary" @click="fetch()">
                                    Refresh
                                </v-btn>
                                <JsonCSV :data="data" class="mx-2">
                                    <v-btn color="primary">
                                        Download
                                    </v-btn>
                                </JsonCSV>
                                <v-btn color="primary" @click="validateInput" :loading="api.isLoading">
                                    Transfer
                                </v-btn>
                            </v-row>
                            <v-row>
                                <v-text-field v-model="search" dense outlined label="Search">
                                </v-text-field>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-toolbar>
            </template>
            <template v-slot:item.welcome_guide_sent="{ item }">
                <span v-if="item.welcome_guide_sent != null">
                    {{ convertTimeZone(item.welcome_guide_sent) }}
                </span>
                <span v-else>
                    -
                </span>
            </template>
            <template v-slot:item.welcome_wa_sent="{ item }">
                <span v-if="item.welcome_wa_sent != null">
                    {{ convertTimeZone(item.welcome_wa_sent) }}
                </span>
                <span v-else>
                    -
                </span>
            </template>
        </v-data-table>

    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
import ASuccessWithoutReload from '../../common/ASuccessWithoutReload.vue';
import AConfirmation from '../../common/AConfirmation.vue';
export default {
    components: {
    ASuccessWithoutReload,
    AConfirmation,
},
    computed: mapState({
        //
    }),
    props: [
        //
    ],
    data() {
        return {
            data: null,
            search: '',
            dataHeader: [
                { text: '#', value: 'Index' },
                { text: 'First Name', value: 'fname' },
                { text: 'Last Name', value: 'lname' },
                { text: 'Email', value: 'email' },
                { text: 'Welcome Guide Sent', value: 'welcome_guide_sent' },
                { text: 'Welcome WA Sent', value: 'welcome_wa_sent' },
                { text:'CS', value:'cs_pic'}
            ],
            isPending: false,
            api: {
                isLoading: false,
                isError: false,
                error: null,
                url: null,
                isSuccesful: false,
                success: null,
            },
        };
    },
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };

        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };

        this.api.callbackSuccess = (resp) => {
            if (resp.class === "getUsersOnboarding") {
                this.data = resp.data;
            }
            if (resp.class == 'transferUserOnboarding') {
                this.api.success = "Succesfully transfer data";
                this.api.isSuccesful = true;
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            let fetchOnboardingApi = this.fetchOnboarding();
            this.$api.fetch(fetchOnboardingApi);
        },
        fetchOnboarding() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/developer/excel/onboarding";
            return tempApi;
        },
        transferUsers() {
            this.isPending = false;
            let transferUsersApi = this.transferUsersApi();
            this.$api.fetch(transferUsersApi);
        },

        transferUsersApi() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API + "/onboarding/transfer";
            return tempApi;
        },
        validateInput() {
            this.isPending = true;
        },
        cancelSubmit() {
            this.isPending = false;
        },
        submit() {
            this.isPending = false;
        },
        convertTimeZone(time) {
            return this.$moment(time).format("LL");
        },
    }
}
</script>